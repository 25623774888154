import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    { path: '/', name: 'main', component: () => import('@/components/Main.vue'), alias: '/main' },
    { path: '/test', name: 'test', component: () => import('@/components/Test.vue') },
  ]
})
