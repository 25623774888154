<template>
  <div id="app">
    <img alt="Cocktail trainer logo" src="./assets/martini.png" class="circle mb-5">

    <b-container fluid="md" style="max-width: 600px">
      <b-nav tabs class="mb-2">
        <b-nav-item to="/" exact exact-active-class="active">
            Lehitse ja otsi
        </b-nav-item>
        <b-nav-item to="/test" exact exact-active-class="active">
            Teadmiste test
        </b-nav-item>
      </b-nav>
      <router-view/>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.circle {
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.circle img {
  border-radius: 50%;
  display: block;
  border: 1px solid #fff;
}

.circle:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.active-link, .active-link:hover {
  color: #84b6e9;
}
</style>
